let app = {}

export default {
  get app () {
    return app
  },
  set (application) {
    app = application
  }
}
